<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <el-breadcrumb separator="/" class="breadcrumb">
                <el-breadcrumb-item>系统设置</el-breadcrumb-item>
                <el-breadcrumb-item>设备管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <h5 class="bt">
                        <img src="../assets/images/equip_01.png" class="ico" alt="" />能源类型
                    </h5>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <button class="ann annbg3">添加能源分类</button>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%">
                            <el-table-column type="index" width="60" label="ID">
                            </el-table-column>
                            <el-table-column prop="mingcheng" label="能源名称">
                            </el-table-column>
                            <el-table-column prop="danwei" label="单位"> </el-table-column>
                            <el-table-column prop="biaozhunmei" label="千克标准煤">
                            </el-table-column>
                            <el-table-column prop="eryanghuatan" label="千克二氧化碳排放">
                            </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-main" @click="handleEdit(scope.$index, scope.row)">修改</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange"  v-model:current-page="pageInfo.pageNum"
                    layout="prev, pager, next, jumper,total" :total="total" :page-size="pageInfo.pageSize">
                </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
export default {
    data() {
        return {
            form: {
                equip: "",
                biaoji: "",
                name: "",
                desc: "",
                level: "",
            },
            pageInfo: {
                pageSize: 10,
                pageNum: 1,
            },
            tableData: [
                {
                    mingcheng: "电",
                    danwei: "kWh",
                    biaozhunmei: "1942",
                    eryanghuatan: "1942",
                },
                {
                    mingcheng: "电",
                    danwei: "kWh",
                    biaozhunmei: "1942",
                    eryanghuatan: "1942",
                },
                {
                    mingcheng: "电",
                    danwei: "kWh",
                    biaozhunmei: "1942",
                    eryanghuatan: "1942",
                },
                {
                    mingcheng: "电",
                    danwei: "kWh",
                    biaozhunmei: "1942",
                    eryanghuatan: "1942",
                },
            ],
            total: 1,
        };
    },
    components: {
        Head,
        Sidebar,
    },
    created() { },
    mounted() { },
    watch: {},
    methods: {
        handleEdit(index, row) { },
        handleDelete(index, row) { },
        handleCurrentChange(val) { },
    },
};
</script>

<style scoped></style>
